<template>
    <div>
        <v-alert
            border="top"
            outlined
            prominent
            icon="mdi-home-city"
            color="accent"
            dark
        >
            <p>
                {{
                    complete
                        ? $t("applications.rental-history-complete")
                        : $t("applications.rental-history-alert")
                }}
            </p>
        </v-alert>
    </div>
</template>
<script>
export default {
    name: "rental-history-notice",
    props: {
        complete: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>