var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: {
            border: "top",
            outlined: "",
            prominent: "",
            icon: "mdi-home-city",
            color: "accent",
            dark: ""
          }
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.complete
                    ? _vm.$t("applications.rental-history-complete")
                    : _vm.$t("applications.rental-history-alert")
                ) +
                " "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }